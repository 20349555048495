import styled, { keyframes } from 'styled-components';

const move = keyframes`
  0% {
   transform: rotate(-45deg);
	 opacity: 1;
  }

	17% {
		opacity: 0.5;
	}
	34%{
    transform: translate(10px, -10px) rotate(-45deg);
	  opacity: 0 ;
		
	}
	50% {
    transform: translate(-5px, 5px) rotate(-45deg);
	  opacity: 0;
  }
  67% {
    opacity:0.5;
  }
  100% {
    transform: rotate(-45deg);
    opacity: 1;
  }
`;

const Link = styled.a`
  font-size: 18px;
  line-height: 30px;
  font-family: ${({ theme }) => theme.fontSans};
  text-transform: uppercase;
  color: inherit;
  position: relative;
  padding-right: 25px;

  &:after {
    content: '';
    position: absolute;
    right:6px;
    bottom: 3px;
    width: 13px;
    height: 13px;
    background: url(${({ arrowWhite }) => arrowWhite ? '../icons/arrow-white.svg' : '../icons/arrow.svg'}) no-repeat;
    background-size: contain;
    transform: rotate(-45deg);
  }

  @media (hover: hover) {

    &:hover {
      text-decoration: underline;

      &:after {
        transform: rotate(-45deg);
        animation: ${move} 0.6s ease-in-out;
    }
    }
  }
`

export default Link
