import React, { useState } from 'react'
import styled from 'styled-components'

import H from '../styled/Heading'
import Item from './PlayerItem'
import B from '../styled/Button'
import L from '../styled/Link'
import W from '../styled/Wrapper'

const Background = styled(W)`
  background-color: ${({ theme }) => theme.black};
  padding-top: 0px;
  padding-bottom: 114px;

  @media only screen and (min-width: 1024px) {
    padding-top: 160px;
    padding-bottom: 160px;
  }
`
const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};

  @media only screen and (min-width: 1024px) {
    margin: 0 auto;
    max-width: 1120px;
    display: grid;
    grid-template-columns: 422px 54%;
    grid-template-areas:
      'title video'
      'select video'
      'more video';
    justify-content: space-between;
    align-items: center;
    height: 340px;
  }
`
const Title = styled(H)`
  padding-top: 116px;
  margin-bottom: 36px;
  grid-area: title;

  @media only screen and (min-width: 1024px) {
    padding-top: 54px;
    align-self: end;
  }
`

const Select = styled(B)`
  appearance: none;
  margin-bottom: 60px;
  background-color: transparent;
  background: url('../icons/dropdown-white.svg') no-repeat;
  background-position: 95% 53.5%;
  grid-area: select;

  &:after {
    display: none;
  }

  @media only screen and (min-width: 1024px) {
    margin-bottom: 36px;
  }
`

const Videos = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 60px;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 0;
    grid-area: video;
    justify-self: end;
    align-self: center;
    background-color: transparent;
    max-width: 100%;
    height: 100%;
  }
`

const More = styled(L)`
  @media only screen and (min-width: 1024px) {
    grid-area: more;
    align-self: end;
    line-height: 1;
    margin-bottom: 65px;
    display: inline-block;
  }
`

const Player = ({ videos, heading, linkLabel, link }) => {
  const [activeIndex, setActiveIndex] = useState('0')

  return (
    <Background>
      <Wrapper>
        <Title size={2}>{heading}</Title>

        <Select
          color="white"
          as="select"
          onChange={e => setActiveIndex(e.target.value)}
        >
          {videos &&
            videos.length > 0 &&
            videos.map((video, i) => {
              return (
                <option key={i} value={i}>
                  {video.title}
                </option>
              )
            })}
        </Select>

        <Videos>
          {videos &&
            videos.length > 0 &&
            videos.map((video, i) => {
              return (
                i === parseInt(activeIndex) && (
                  <Item
                    key={i}
                    isActive={i === parseInt(activeIndex)}
                    id={video.youTubeId}
                  />
                )
              )
            })}
        </Videos>

        <More arrowWhite href={link} target="_blank">
          {linkLabel}
        </More>
      </Wrapper>
    </Background>
  )
}

export default Player
