import React from 'react'
import SEO from '../components/seo'

import Top from '../components/media/Top'
import Player from '../components/media/Player'

const Media = props => {
  const data = props.data.dis.pageMedias[0]

  const {
    downloadButtonLabel,
    downloadFile,
    intro,
    mediaVideos,
    seoDescription,
    seoImage,
    seoTitle,
    videosHeading,
    youTubeLink,
    youTubeLinkLabel,
    downloadUrl
  } = data

  return (
    <div>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={seoImage && seoImage.url}
      />
      <Top intro={intro} btn={downloadButtonLabel} download={downloadUrl} />
      <div id="d-01"></div>
      <Player
        videos={mediaVideos}
        heading={videosHeading}
        linkLabel={youTubeLinkLabel}
        link={youTubeLink}
      />
      <div id="l-01"></div>
      <div id="d-02"></div>
      <div id="l-02"></div>
      <div id="d-03"></div>
      <div id="l-03"></div>
      <div id="d-04"></div>
      <div id="l-04"></div>
    </div>
  )
}

export const query = graphql`
  query MediaPageQuery {
    dis {
      pageMedias(where: { id: "ckiecbsio26zz0a47p6redcay" }) {
        downloadButtonLabel
        downloadFile {
          url
        }
        downloadUrl
        intro
        mediaVideos {
          title
          youTubeId
        }
        seoDescription
        seoImage {
          url
        }
        seoTitle
        title
        videosHeading
        youTubeLink
        youTubeLinkLabel
      }
    }
  }
`

export default Media
