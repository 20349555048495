import React from 'react'
import styled from 'styled-components'

const Video = styled.iframe`
  width: 100%;
  height: 100%;
  min-height: 190px;
  border: none;
  /* display: ${({ isActive }) => (isActive ? 'block' : 'none')}; */
  opacity: ${({ isActive }) => (isActive ? '1' : '0')};
  transition: opacity 1s ease-out;

  @media only screen and (min-width: 768px) and (orientation: portrait) {
    min-height: 50vw;
  }
`

const PlayerItem = ({ id, isActive }) => {
  return (
    <Video
      isActive={isActive}
      src={`https://www.youtube.com/embed/${id}`}
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen="1"
      frameBorder="0"
    ></Video>
  )
}

export default PlayerItem
