import React from 'react'
import styled from 'styled-components'

import W from '../styled/Wrapper'
import P from '../styled/Paragraph'
import B from '../styled/Button'

const Wrapper = styled(W)`
  padding-top: 150px;
  padding-bottom: 100px;
  background-color: ${({ theme }) => theme.white};

  @media only screen and (min-width: 1024px) {
    padding-top: 185px;
    padding-bottom: 130px;
    margin: 0 auto;
  }
`

const Text = styled(P)`
  line-height: 34px;
  margin-bottom: 38px;

  @media only screen and (min-width: 1024px) {
    max-width: ${({ theme }) => theme.maxWidth};
    line-height: 48px;
    margin-bottom: 65px;
    width: 90%;
  }
`

const Top = ({ intro, btn, download }) => {
  return (
    <Wrapper>
      <Text size="l">{intro}</Text>
      {download && (
        <B href={download} target="_blank" color="black">
          {btn}
        </B>
      )}
    </Wrapper>
  )
}

export default Top
